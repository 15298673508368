@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;1,600&display=swap);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none !important;
    outline: none;
    font-family: 'Poppins', sans-serif;
}
.container {
    background: linear-gradient(13deg, #850c62, #f80759);
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.app-wrapper {
    background-color: #fff;
    min-width: 350px;
    min-height: 650px;
    padding: 30px;
    border-radius: 5px;
}
.title {
    color: #f80759;
    text-align: center;
    margin: 80px 0 40px 0;
}
.name,
.email,
.password {
    margin: 20px;
    color: #969292;
    font-size: 16px;
}
.password {
    margin-bottom: 40px;
}
.input {
    width: 100%;
    height: 32px;
    border-bottom: 2px solid black !important;
    outline: none;
    font-size: 20px;
    margin-bottom: 10px;
}
.error {
    color: red;
}
.submit {
    display: flex;
    font-size: 20px;
    border-radius: 25px;
    color: #fff;
    padding: 10px 60px;
    background: linear-gradient(to right, #ab1dcf, #f80759);
    cursor: pointer;
    transition: all 300ms ease;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

button:active {
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
}
.form-success {
    display: flex;
    justify-content: center;
    margin-top: 250px;
    min-width: 370px;
    color: #f80759;
}

